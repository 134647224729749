import React, { useEffect, useState } from "react";
import { Grid,Box } from "@mui/material";
import "./index.css";
import tabImage1 from "../../assests/tab-image-one.png";
import tabImage2 from "../../assests/tab-image-two.png";
import tabImage3 from "../../assests/tab-image-three.png";
import tabImage4 from "../../assests/tab-image-four.png";
import locationIcon from "../../assests/location.png";
import fingerprint from "../../assests/fingerprint.svg";
import rulesetting from "../../assests/rulesetting.svg";
import notifications from "../../assests/notifications.svg";
import locationHoverImage from "../../assests/locationHover.png";
import rulesettingHover from "../../assests/rulesettingHover.png";
import fingerprintHover from "../../assests/fingerprintHover.png";
import notificationsHover from "../../assests/notificationsHover.png";

import iPaApplePencil from "../../assests/iPaApplePencil.png";
import { BulletPoint } from "../BulletPoint/BulletPoint";

const bulletPointData = [
  {
    heading: "Range of fitness and wellness categories",
    subHeading:
      "Let GripStudio users know what you offer. Choose between 25+ categories to showcase your business.",
    image: locationHoverImage,
  },
  {
    image:rulesettingHover,
    heading:"Manage and maintain",
    subHeading: "Easily view and manage your class setup, attendance, and more.",
  },
  {
    image:notificationsHover,
    heading:"Secure your payments",
    subHeading:"Ensure secure payments by seamlessly integrating with Stripe Connect."
  },
  {
    image: fingerprintHover,
    heading:"Find your stride",
    subHeading:"View transactions, analytics on class revenue, and performance from previous classes.",
  },
];

function GrowingFitness() {
  const [selectedBulletPoint, setSelectedBulletPoint] = useState(0);

  const handleBulletPointClick = (index: number) => {
    setSelectedBulletPoint(index);
  };

  const getTabImageBySelectedIndex = () => {
    switch (selectedBulletPoint) {
      case 1:
        return tabImage2;
      case 2:
        return tabImage3;
      case 3:
        return tabImage4;
      default:
        return tabImage1;
    }
  };

  const getIconImageBySelectedIndex = () => {
    switch (selectedBulletPoint) {
      case 1:
        return rulesettingHover;
      case 2:
        return notificationsHover;
      case 3:
        return fingerprintHover;
      default:
        return locationHoverImage;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedBulletPoint((prevIndex) => (prevIndex + 1) % 4);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Grid
      container
      className="growing-section background-logo"
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <img
        src={iPaApplePencil}
        className="iPadApplePencil"
        alt="background-logo"
        loading="lazy"
      />
      <Grid item xs={12} sm={5} lg={4} md={5} className="web-view">
        <img
          src={getTabImageBySelectedIndex()}
          className="tab-image"
          alt="mobile-demo-gif"
          loading="lazy"
        />
      </Grid>
      <Grid item xs={12} sm={7} lg={5} md={7} className="bullet-point-section2">
        <Grid className="growing-text">
          Growing your fitness & wellness business has never been easier
        </Grid>
        <Box className="fore-desktop">
          <Box className="bullet-point-section2">
              <BulletPoint
                image={
                  selectedBulletPoint === 0
                    ? getIconImageBySelectedIndex()
                    : locationIcon
                }
                heading={"Range of fitness and wellness categories"}
                subHeading={
                  "Let GripStudio users know what you offer. Choose between 25+ categories to showcase your business."
                }
                onClick={() => handleBulletPointClick(0)}
                isSelected={selectedBulletPoint === 0}
              />
              <BulletPoint
                image={
                  selectedBulletPoint === 1
                    ? getIconImageBySelectedIndex()
                    : rulesetting
                }
                heading={"Manage and maintain"}
                subHeading={
                  "Easily view and manage your class setup, attendance, and more."
                }
                onClick={() => handleBulletPointClick(1)}
                isSelected={selectedBulletPoint === 1}
              />
              <BulletPoint
                image={
                  selectedBulletPoint === 2
                    ? getIconImageBySelectedIndex()
                    : notifications
                }
                heading={"Secure your payments"}
                subHeading={
                  "Ensure secure payments by seamlessly integrating with Stripe Connect."
                }
                onClick={() => handleBulletPointClick(2)}
                isSelected={selectedBulletPoint === 2}
              />
              <BulletPoint
                image={
                  selectedBulletPoint === 3
                    ? getIconImageBySelectedIndex()
                    : fingerprint
                }
                heading={"Find your stride"}
                subHeading={
                  "View transactions, analytics on class revenue, and performance from previous classes."
                }
                onClick={() => handleBulletPointClick(3)}
                isSelected={selectedBulletPoint === 3}
              />
          </Box>
        </Box>
        <Box className="fore-mobile ">
          {bulletPointData.map((bullet) => {
            return (
              <BulletPoint
                image={bullet.image}
                heading={bullet.heading}
                subHeading={bullet.subHeading}
                 />
            );
          })};
        </Box>
      </Grid>
    </Grid>
  );
}

export default GrowingFitness;

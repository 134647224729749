import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import gripstudiologo from "../../src/assests/logo.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const SetPassword = () => {
  const { access_code: access_token } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accessCodeVerified, setAccessCodeVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleAndroidClick = () => {
    const url = "https://play.google.com/store/apps/details?id=com.gripstudio.app&pli=1";
    window.open(url, "_blank");
  };
  const handleAppleClick = () => {
    const url = "https://apps.apple.com/in/app/gripstudio/id6473517079";
    window.open(url, "_blank");
  };

  const validateForm = (fieldName: keyof typeof formData) => {
    let isValid = true;
    const newErrors = { ...errors };

    switch (fieldName) {
      case "password":
        // Validate password
        if (!formData.password.trim()) {
          newErrors.password = "Password is required";
          isValid = false;
        } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(formData.password)) {
          newErrors.password = "Password must contain at least one number, one uppercase letter, and one lowercase letter";
          isValid = false;
        } else {
          newErrors.password = "";
        }
        break;

      case "confirmPassword":
        if (!formData.confirmPassword.trim()) {
          newErrors.confirmPassword = "Confirm Password is required";
          isValid = false;
        } else if (formData.password !== formData.confirmPassword) {
          newErrors.confirmPassword = "Confirm Password must be similar to the Password";
          isValid = false;
        } else {
          newErrors.confirmPassword = "";
        }
        break;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name as keyof typeof formData;
    validateForm(fieldName);
  };
  useEffect(() => {
    let body = document.querySelector("body");
    console.log(body);
    body && body.style && body?.style?.setProperty("background", "#0c0c0c");
    console.log(body);
  }, []);

  const resetForm = () => {
    setFormData({
      password: "",
      confirmPassword: "",
    });
    setErrors({
      password: "",
      confirmPassword: "",
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = Object.keys(formData).every((fieldName) => validateForm(fieldName as keyof typeof formData));

    if (isValid) {
      setIsLoading(true); // Set loading state only when the form is valid
      // .then((token) => {
      const postData = {
        ...formData,
      };

      // const { password, ...postDataWithoutPassword } = postData;
      return axios
        .post(`${process.env.REACT_APP_SERVER_URL}/set-password/${access_token}`, postData)
        .then((response) => {
          toast.success("Congrats! you need to download the app from store to continue.");
          setAccessCodeVerified(true);
          resetForm();
        })
        .catch((error) => {
          let message = error.message;
          if (error.code === "ERR_BAD_REQUEST") {
            message = error.response.data.message;
          }
          toast.error(message);
          setAccessCodeVerified(false);
        })
        .finally(() => {
          setIsLoading(false); // Reset loading state when submission is complete
        });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={3} lg={3} p={4} className={"logo"}>
        <img src={gripstudiologo} alt="studio-logo" className="gripstudioLogo-header" loading="lazy" />
      </Grid>
      {/* <HeaderSection
                scrolled={true}
                isUsers={isUsers}
                setIsUsers={setIsUsers}
            /> */}
      <Grid item xs={12} className="get-early-section">
        <Grid
          container
          className="get-early-section bg-2"
          justifyContent="center" // Center the Grid item within the parent container
          alignItems="center"
        >
          {/* <Grid item xs={12} sm={4} lg={4} md={4} className="early-section">
                        <Grid className="h1 get-early-heading">Get early access</Grid>
                        <Grid className="p1">
                            Sign up today to be one of the first 10,000 users to receive early
                            access to GripStudio's network of gyms, studios, wellness solutions,
                            and trainers.
                        </Grid>
                    </Grid> */}
          {access_token && !accessCodeVerified ? (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className="form"
              container // This allows you to use container-specific properties like justifyContent and alignItems
              mt={20}
              // alignItems="center"
              direction="column" // Ensures that the content inside the Grid is stacked vertically
              style={{ minHeight: "100vh" }} // Optionally, make sure it takes the full height of the viewport
              textAlign="center"
            >
              <form onSubmit={handleSubmit}>
                <Typography variant="h4" sx={{ color: "#FFFFFF", pb: 2 }}>
                  Set Password
                  <p className="p1">You need to set your password to activate the account</p>
                </Typography>

                <TextField
                  name="password"
                  label="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={!!errors.password}
                  helperText={errors.password}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    sx: {
                      backgroundColor: "#1F1F1F",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((prevShow) => !prevShow)} edge="end" sx={{ color: "#E7E3FCAD" }}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#8B8B8B",
                    },
                  }}
                />
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    sx: {
                      backgroundColor: "#1F1F1F",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword((prevShow) => !prevShow)} edge="end" sx={{ color: "#E7E3FCAD" }}>
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#8B8B8B",
                    },
                  }}
                />
                <Button
                  className="active-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ mt: 2 }}
                  // disabled={!isFormValid || isLoading}
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </Button>
              </form>
            </Grid>
          ) : (
            <></>
          )}
          {accessCodeVerified ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="form"
              // container // This allows you to use container-specific properties like justifyContent and alignItems
              mt={20}
              // alignItems="center"
              // direction="column" // Ensures that the content inside the Grid is stacked vertically
              style={{ minHeight: "100vh" }} // Optionally, make sure it takes the full height of the viewport
              // textAlign="center"
            >
              <Grid className="slider-heading h1">
                Join the fitness and <br /> wellness community
                <Grid className="store-button">
                  <button className="app-button" onClick={handleAndroidClick}>
                    Play Store download
                  </button>
                  <button className="app-button" onClick={handleAppleClick}>
                    App Store download
                  </button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SetPassword;
